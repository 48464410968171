import React, { useLayoutEffect, useRef, useState } from "react";
import ArtistsList from "../components/artists-list";
import ArtistsHome from "../components/artists-home";
import Layout from "../components/layout";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Partners from "../components/partners";
import NewsHome from "../components/news-home";
import Image from "../components/image";
import logo from "../images/logo.svg";
import { StaticImage } from "gatsby-plugin-image";

const IndexGallery = () => {
  const intl = useIntl();

  const gallery = useRef(null);
  const galleryWrapper = useRef(null);

  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const recalculate = debounce(() => {
    if (gallery.current?.scrollHeight) {
      gallery.current.style.animation = "none";
      document.documentElement.style.setProperty(
        "--gallery",
        `calc(${gallery.current.scrollHeight}px - 95vh)`
      );
      gallery.current.style.animation =
        "scroll-top-bottom 45s cubic-bezier(0.150, 0.075, 0.845, 0.950) alternate infinite";
    }
  });

  useLayoutEffect(() => {
    const galleryObserver = new ResizeObserver(() => {
      recalculate();
    });

    galleryObserver.observe(gallery.current);

    const images = document.querySelectorAll(".start-gallery-item");
    images.forEach((image) => {
      // add transform-gpu to image where it appears on screen
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.remove(
                "sm:opacity-70",
                "sm:scale-[.9]",
                "sm:grayscale"
              );
            } else {
              entry.target.classList.add(
                "sm:opacity-70",
                "sm:scale-[.9]",
                "sm:grayscale"
              );
            }
          });
        },
        {
          root: galleryWrapper.current,
          rootMargin: "0px",

          threshold: 0.5,
        }
      );
      observer.observe(image);
    });

    recalculate();
  });

  return (
    <>
      <Layout>
        <main>
          <header className=" -mt-[3.5rem] sm:-mt-[5.5rem] mb-10 sm:mb-40 min-h-screen relative overflow-clip border-b-4 sm:border-b-8 border-utdf-border">
            <div
              ref={galleryWrapper}
              className="h-full w-full overflow-hidden absolute inset-0 z-[1]"
            >
              <div
                ref={gallery}
                className="start-gallery will-change-transform"
              >
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/1.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/2.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/3.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/5.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/34.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/4.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/6.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/7.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/8.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/9.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/10.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/11.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/12.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/13.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/14.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/15.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/16.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/17.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/18.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/19.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/20.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/21.jpg`}
                    alt=""
                  />
                </div>
                <div>
                  <StaticImage
                    loading="eager"
                    src={`../images/main/23.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/24.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/25.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/26.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/27.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/28.jpg`}
                    alt=""
                    className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale"
                  />
                  {/*
              <StaticImage
loading="eager"
                src={`../images/main/29.jpg`}
                alt=""
                className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale"
              /> */}
                </div>
                <div>
                  <StaticImage
                    loading="eager"
                    src={`../images/main/30.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/31.jpg`}
                    alt=""
                  />
                </div>
                {/* <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/32.jpg`}
                    alt=""
                  />
                </div> */}
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/33.jpg`}
                    alt=""
                  />
                </div>
                <div className="start-gallery-item transform-gpu  will-change-auto transition duration-700 ease-in-out sm:opacity-70 sm:scale-[.9] sm:grayscale">
                  <StaticImage
                    loading="eager"
                    src={`../images/main/35.jpg`}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="absolute z-[3] inset-0  space-y-10 md:space-y-20 flex-col ">
              <div className=" min-h-screen flex flex-col justify-center items-center space-y-6 sm:space-y-8">
                <div className="absolute inset-0 z-[-2] bg-gradient-radial from-black  to-transparent opacity-90"></div>

                <img
                  src={logo}
                  alt="Up To Date Festival Białystok"
                  className="max-w-[14rem] sm:max-w-[20rem] shrink-0 w-full mx-auto"
                />
                <h1 className="sr-only">Up To Date Festival Białystok</h1>
                <p className="text-3xl sm:text-[3rem] sm:leading-tight  text-left text-utdf-secondary">
                  <span className="font-gambetta italic font-medium uppercase">
                    {intl.formatMessage({ id: "FullDate" })}
                  </span>
                  <span className="font-gambetta  block">SECRET LOCATION</span>
                  <span className="font-gambetta  block">BIAŁYSTOK</span>
                </p>
                <div className="grid grid-cols-2 items-center gap-4 sm:gap-8">
                  <div className="flex justify-end">
                    <Link
                      className="bg-utdf-secondary inline-block border border-utdf-secondary rounded-md text-lg sm:text-2xl font-bold  text-utdf-dark px-3 py-2 sm:py-3 sm:px-4"
                      to="/timetable"
                    >
                      {intl.formatMessage({ id: "Timetable" })}
                    </Link>
                  </div>
                  <div className="">
                    <a
                      className="bg-utdf-secondary inline-block border border-utdf-secondary rounded-md text-lg sm:text-2xl font-bold  text-utdf-dark px-3 py-2 sm:py-3 sm:px-4"
                      href="https://goingapp.pl/wydarzenie/up-to-date-festival-2023/czerwiec-2023"
                    >
                      {intl.formatMessage({ id: "Get Tickets" })}
                    </a>
                  </div>

                </div>
                {intl.locale === "pl" && (
                    <div className="flex justify-center">
                      <Link
                        className="bg-[#ff0037] inline-block border border-[#ff0037] rounded-md text-lg sm:text-2xl font-bold  text-white px-3 py-2 sm:py-3 sm:px-4"
                        to="/wazne-informacje"
                      >
                        {intl.formatMessage({ id: "Where" })}
                      </Link>
                    </div>
                  )}
                  {intl.locale === "en" && (
                      <div className="flex justify-center">
                        <Link
                        className="bg-[#ff0037] inline-block border border-[#ff0037] rounded-md text-lg sm:text-2xl font-bold  text-white px-3 py-2 sm:py-3 sm:px-4"
                        to="/important-information"
                        >
                          {intl.formatMessage({ id: "Where" })}
                        </Link>
                      </div>
                    )}
                    {intl.locale === "ua" && (
                        <div className="flex justify-center">
                          <Link
                        className="bg-[#ff0037] inline-block border border-[#ff0037] rounded-md text-lg sm:text-2xl font-bold  text-white px-3 py-2 sm:py-3 sm:px-4"
                        to="/yak-potrapiti-na-festival"
                          >
                            {intl.formatMessage({ id: "Where" })}
                          </Link>
                        </div>
                      )}
              </div>
            </div>
          </header>

          <ArtistsHome />

          <NewsHome />
        </main>
      </Layout>
    </>
  );
};

export default IndexGallery;
