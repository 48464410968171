import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import moment from "moment";

import Layout from "../components/layout";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import Image from "../components/image";

const startHour = 18;

const Timetable = ({ data }) => {
  const intl = useIntl();
  const [stage, setStage] = useState(null);
  const [date, setDate] = useState(null);
  const [place, setPlace] = useState(null);
  const [visibleArtists, setVisibleArtists] = useState(1);

  const [tableView, setTableView] = useState(true);

  const stages = data.allWpStages.nodes.sort((a, b) => {
    return a.stages.order - b.stages.order;
  });



  useEffect(() => {
    countVisibleArtists();
  }, [stage, date, place]);

  const countVisibleArtists = () => {
    let counter = 0;
    data.allWpArtists.nodes.map((i) =>
      (stage === null || stage.includes(i.artist.scene[0].id)) &&
      (date === null || i.artist.festivaldate[0].id === date)
        ? counter++
        : null
    );

    setVisibleArtists(counter);
  };

  const artists = [
    ...data.allWpArtists.nodes.filter(i => i.artist?.artisttype === "artist" ).sort((a, b) => {
      return (
        new Date(a.artist.hour.replace(/-/g, "/")) -
        new Date(b.artist.hour.replace(/-/g, "/"))
      );
    }),
  ];

  const startDate = new Date("2020-01-01T18:00");

  const getFormattedStartDate = (date) => {
    const splitted = date.split(".");
    return new Date(splitted[2], splitted[1] - 1, splitted[0], startHour, 0);
  };

  const getInitialDistance = (startDate, artistDate) => {
    // console.log(new Date(artistDate.replace(/-/g, "/")));
    // console.log(getFormattedStartDate(startDate));
    // console.log(
    //   new Date(artistDate.replace(/-/g, "/")).getTime(),
    //   getFormattedStartDate(startDate).getTime()
    // );
    // console.log(
    //   (new Date(artistDate.replace(/-/g, "/")).getTime() -
    //     getFormattedStartDate(startDate).getTime()) /
    //     1000 /
    //     60 /
    //     15
    // );

    return (
      (new Date(artistDate.replace(/-/g, "/")) -
        getFormattedStartDate(startDate)) /
      1000 /
      60 /
      15
    );
  };

  const getLength = (artist) => {
    return (
      (new Date(artist.artist.hourEnd.replace(/-/g, "/")) -
        new Date(artist.artist.hour.replace(/-/g, "/"))) /
      1000 /
      60 /
      15
    );
  };

  return (
    <Layout>
      <main className="">
        <header className=" py-10 sm:pt-[7.5rem]">
          <h1 className="big-heading  flex justify-between items-center gap-4 px-4 flex-wrap">
            Timetable
            <Link className="text-xl lg:text-2xl font-normal" to="/artists">
              {intl.formatMessage({ id: "Artists" })} &rarr;
            </Link>
          </h1>
        </header>

        <div className="max-w-7xl mx-auto  space-y-8 lg:space-y-16 lg:py-8 px-4 lg:px-8">
          <div className="grid grid-cols-2 border border-utdf-border">
            <button
              onClick={() => setTableView(true)}
              className={`${
                tableView
                  ? "bg-utdf-border text-utdf-dark font-bold"
                  : "text-utdf-border"
              } p-2 `}
            >
              {intl.formatMessage({ id: "TableView" })}
            </button>
            <button
              onClick={() => setTableView(false)}
              className={`${
                !tableView
                  ? "bg-utdf-border text-utdf-dark font-bold"
                  : "text-utdf-border"
              } p-2 `}
            >
              {intl.formatMessage({ id: "ListView" })}
            </button>
          </div>

          {tableView
            ? data.allWpDates.nodes.map((date, key) => (
                <section key={key} className="-mx-4">
                  <h2 className="text-2xl border-b border-utdf-border py-2 mb-16 bg-utdf-border text-center text-utdf-dark font-bold sticky top-[3.5rem] md:top-[6.5rem] z-20">
                    {date.title}
                  </h2>

                  {key === 1 && (
                    <>
                      <a
                        className="text-utdf-border leading-snug p-2 text-xl mb-16 flex items-center gap-4 justify-center"
                        href="https://www.facebook.com/events/211204298007831"
                      >
                        <span className="text-xs md:text-sm text-center">12:30 <span className="block sm:inline">-</span> 16:15</span>
                        <span>
                          <span className="font-bold underline">
                            Do We Really Care? #balance #climatetech
                            #responsibility - conference
                          </span>
                          <br />
                          <span className="text-base">
                            Nie Teatr, Henryka Sienkiewicza 4,Białystok
                          </span>
                        </span>
                        <span className="italic text-sm ">free</span>
                      </a>
                    </>
                  )}

<a
                        className="text-utdf-border leading-tight mb-12 sm:mb-24 p-2 flex items-center gap-4 justify-center"
                        href="https://facebook.com/events/s/otwarte-warsztaty-dj-skie-proj/2866548310142684/"
                      >
                        <span className="text-xs md:text-sm text-center">12:00 <span className="block sm:inline">-</span> 18:00</span>
                        <span>
                          <span className="font-bold underline">
                          Otwarte warsztaty DJ-skie: Projekt New Hope × UTDF
                          </span>
                          <br />
                          <span className="text-xs">
                            Projekt New Hope, Wyszyńskiego 6a, Białystok
                          </span>
                        </span>
                        <span className="italic text-sm ">free</span>
                      </a>


                  <div className="flex justify-center">
                  <h3 className="text-lg sm:text-2xl text-center px-4 py-2 text-black rounded-full bg-utdf-secondary font-bold mb-8">
                    SECRET LOCATION
                  </h3></div>

                  <div className="grid grid-cols-2 w-full text-center text-sm sm:text-lg font-editorial italic text-utdf-secondary pl-14">
                    <span>DON'T WORRY</span>
                    <span>BE YOURSELF</span>
                  </div>

                  <div
                    className="overflow-x-auto"
                    style={{
                      background:
                        "repeating-linear-gradient(0deg, transparent, transparent 23px, rgba(255, 255, 255, .1) 23px, rgba(255, 255, 255, .1) 24px )",
                    }}
                  >
                    <div
                      className="flex flex-nowrap  overflow-x-auto"
                      style={{}}
                    >
                      <div className="sticky left-0 z-10 basis-[50px]">
                        <div className="grid auto-rows-[1rem] gap-2 -translate-y-5 z-10 ">
                          <div className="row-span-2"></div>
                          {[...Array(30)].map((_, i) => (
                            <div className="row-span-2 whitespace-nowrap  text-utdf-border pl-1 pr-2 md:pr-4 text-xs md:text-sm">
                              {String(
                                new Date(
                                  startDate.getTime() + i * 30 * 60000
                                ).getHours()
                              ).padStart(2, "0")}
                              :
                              {String(
                                new Date(
                                  startDate.getTime() + i * 30 * 60000
                                ).getMinutes()
                              ).padStart(2, "0")}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div
                        className="grid gap-2 grow pr-1"
                        style={{
                          gridAutoFlow: "column",
                          gridAutoColumns:
                            "minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr)  minmax(150px, 1fr)",
                        }}
                      >
                        {stages.map((stage) => {
                          return artists.filter(
                            ({ artist }) =>
                              artist?.festivaldate[0]?.id === date.id &&
                              artist.scene[0].id === stage.id
                          ).length > 0 ? (
                            <div className="grid auto-rows-[1rem] gap-2  h-full -translate-y-1">
                              {artists
                                .filter(
                                  (artist) =>
                                    artist?.artist?.festivaldate[0]?.id ===
                                      date.id &&
                                    artist.artist.scene[0].id === stage.id
                                )
                                .map((artist, key) => (
                                  <>
                                    {key === 0 ? (
                                      getInitialDistance(
                                        date.title,
                                        artist.artist.hour
                                      ) > 0 ? (
                                        <div
                                          style={{
                                            gridRow: `span  ${
                                              getInitialDistance(
                                                date.title,
                                                artist.artist.hour
                                              ) + 2
                                            }`,
                                          }}
                                          className="flex justify-center text-center items-end translate-y-1"
                                        >
                                          <span className="sticky block top-40 font-editorial font-bold text-utdf-border tracking-wide"></span>
                                        </div>
                                      ) : (
                                        <div className="row-span-2 bg-opacity-90 p-2 text-center flex justify-center items-end translate-y-3 ">
                                          <span className="sticky block top-40 font-editorial font-bold text-utdf-border tracking-wide"></span>
                                        </div>
                                      )
                                    ) : null}

                                    <div
                                      style={{
                                        gridRow: `span  ${getLength(artist)}`,
                                      }}
                                      className="border border-utdf-border bg-utdf bg-opacity-75  rounded-lg flex justify-center text-center items-center font-bold text-utdf-border "
                                    >
                                      {artist.artist.timetableOnly ? (
                                        <p className="p-2">{artist.title}</p>
                                      ) : (
                                        <Link
                                          to={`/artists/${artist.slug}`}
                                          title={artist.title}
                                          className="grow flex items-center justify-center text-sm sm:text-base text-center h-full w-full p-2 gap-2"
                                        >
                                          {artist.title.split(" live")[0]}{" "}
                                          {artist.title.split(" live").length >
                                          1 ? (
                                            <span className="font-editorial italic text-sm font-normal">
                                              {" "}
                                              live
                                            </span>
                                          ) : null}
                                        </Link>
                                      )}
                                    </div>
                                  </>
                                ))}
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              ))
            : data.allWpDates.nodes.map((date, key) => (
                <section className="pb-10">
                  <h2 className="-mx-4 text-2xl border-b border-utdf-border py-2 mb-8 bg-utdf-border text-center text-utdf-dark font-bold sticky top-16 md:top-[6.5rem] z-20">
                    {date.title}
                  </h2>

                  {key === 1 && (
                    <>
                      <a
                        className="text-utdf-border leading-snug p-2 text-xl mb-8 flex items-center gap-4 justify-center"
                        href="https://www.facebook.com/events/211204298007831"
                      >
                        <span className="text-xs md:text-sm text-center">12:30 <span className="block sm:inline">-</span> 16:15</span>
                        <span>
                          <span className="font-bold underline">
                            Do We Really Care? #balance #climatetech
                            #responsibility - conference
                          </span>
                          <br />
                          <span className="text-base">
                            Nie Teatr, Henryka Sienkiewicza 4,Białystok
                          </span>
                        </span>
                        <span className="italic text-sm ">free</span>
                      </a>
                    </>
                  )}

<a
                        className="text-utdf-border leading-tight mb-14 p-2 flex items-center gap-4 justify-center"
                        href="https://facebook.com/events/s/otwarte-warsztaty-dj-skie-proj/2866548310142684/"
                      >
                        <span className="text-xs md:text-sm text-center">12:00 <span className="block sm:inline">-</span> 18:00</span>
                        <span>
                          <span className="font-bold underline">
                          Otwarte warsztaty DJ-skie: Projekt New Hope × UTDF
                          </span>
                          <br />
                          <span className="text-xs">
                            Projekt New Hope, Wyszyńskiego 6a, Białystok
                          </span>
                        </span>
                        <span className="italic text-sm ">free</span>
                      </a>


                  <div className="flex justify-center">
                  <h3 className="text-lg sm:text-2xl text-center px-4 py-2 text-black rounded-full bg-utdf-secondary font-bold mb-8">
                    SECRET LOCATION
                  </h3></div>
                  <div
                    className="grid gap-8"
                    style={{
                      gridTemplateColumns:
                        "repeat(auto-fit, minmax(200px, 1fr))",
                    }}
                  >
                    {stages.map((stage, key) => {
                      return artists.filter(
                        ({ artist }) =>
                          artist?.festivaldate[0]?.id === date.id &&
                          artist.scene[0].id === stage.id
                      ).length > 0 ? (
                        <section key={key}>
                          {artists
                            .filter(
                              (artist) =>
                                artist?.artist?.festivaldate[0]?.id ===
                                  date.id &&
                                artist.artist.scene[0].id === stage.id
                            )
                            .map((artist, key) => (
                              <>
                                {key === 0 ? (
                                  <h3 className="mb-4 font-bold font-editorial tracking-wider text-utdf-border">
                                    {intl.locale === "pl"
                                      ? stage.title
                                      : stage.stages.titleEn}
                                  </h3>
                                ) : null}

                                <p className="mb-2">
                                  {" "}
                                  <span className="text-xs md:text-sm text-utdf-secondary inline-block pr-2">
                                    {String(
                                      new Date(
                                        artist.artist.hour.replace(/-/g, "/")
                                      ).getHours()
                                    ).padStart(2, "0")}
                                    :
                                    {String(
                                      new Date(
                                        artist.artist.hour.replace(/-/g, "/")
                                      ).getMinutes()
                                    ).padStart(2, "0")}{" "}
                                  </span>
                                  <Link
                                    to={`/artists/${artist.slug}`}
                                    title={artist.title}
                                  >
                                    {artist.title.split(" live")[0]}{" "}
                                    {artist.title.split(" live").length > 1 ? (
                                      <span className="font-editorial italic text-sm font-normal">
                                        {" "}
                                        live
                                      </span>
                                    ) : null}
                                  </Link>
                                </p>
                              </>
                            ))}
                        </section>
                      ) : null;
                    })}
                  </div>
                </section>
              ))}
        </div>
        {/*
      <ul
        className="grid gap-4 sm:gap-6"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(212px, 1fr))",
        }}
      >
        {artists.map(
          (i, key) =>
            i.artist.festivaldate[0].title.includes("2022") && (
              <li
                className={`basis-[270px] shrink-0 group ${
                  (stage === null ||
                    stage.includes(i.artist.scene[0].id)) &&
                  (date === null ||
                    i.artist.festivaldate[0].id === date)
                    ? "block"
                    : "hidden"
                }`}
                key={key}
              >
                <Link
                  className="flex flex-col justify-between h-full pb-8"
                  to={`/artists/${i.slug}`}
                  title={i.title}
                >
                  <div className=" mb-2">
                    <GatsbyImage
                      image={
                        i.artist.photo
                          ? getImage(i.artist.photo.localFile)
                          : null
                      }
                      alt={i.artist.title}
                      className="w-full h-full block"
                    />
                  </div>
                  <p className="mt-2 text-2xl leading-9 mb-auto group-hover:underline">
                    {i.title}
                  </p>
                  <div className="flex flex-col justify-between">
                    <div className="border-t mt-4 pt-4 border-utdf-border gap-4 flex font-gambetta justify-between">
                      <p>
                        {intl.locale === "pl"
                          ? i.artist.scene[0].title
                          : i.artist.scene[0].stages.titleEn}
                      </p>
                      <p>{i.artist.festivaldate[0].title} </p>
                    </div>
                  </div>
                </Link>
              </li>
            )
        )}
      </ul> */}
      </main>
    </Layout>
  );
};

const TimetableWrapper = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query ShortArtistsListTimetable {
            allWpPlaces {
              nodes {
                id
                places {
                  nameEn
                  namePl
                }
              }
            }
            allWpStages(sort: { fields: slug }) {
              nodes {
                id
                slug
                stages {
                  place {
                    ... on WpPlaces {
                      id
                      places {
                        nameEn
                        namePl
                      }
                    }
                  }
                  order
                  titleEn
                }
                title
              }
            }
            allWpDates(sort: { fields: slug }) {
              nodes {
                title
                slug
                id
              }
            }
            allWpArtists(sort: { fields: slug }) {
              nodes {
                id
                title
                slug
                artist {
                  photo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  festivaldate {
                    ... on WpDates {
                      id
                      title
                    }
                  }
                  artisttype
                  hour
                  hourEnd
                  timetableOnly
                  scene {
                    ... on WpStages {
                      id
                      stages {
                        place {
                          ... on WpPlaces {
                            id
                            places {
                              nameEn
                              namePl
                            }
                          }
                        }
                        titleEn
                      }
                      title
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          return <Timetable data={data} />;
        }}
      />
    </>
  );
};

export default TimetableWrapper;
