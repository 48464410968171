import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link, useIntl } from "gatsby-plugin-react-intl";

const startsWithNumber = (str) => {
  return /^[0-9]/.test(str.slug) && str.artist.timetableOnly !== true;
};

const startsWithLetter = (str) => {
  return /^[a-zA-Z]/.test(str.slug) && str.artist.timetableOnly !== true;
};

const ArtistsSelector = ({ data }) => {
  const intl = useIntl();
  const [stage, setStage] = useState(null);
  const [date, setDate] = useState(null);
  const [place, setPlace] = useState(null);
  const [visibleArtists, setVisibleArtists] = useState(1);

  useEffect(() => {
    countVisibleArtists();
  }, [stage, date, place]);

  const countVisibleArtists = () => {
    let counter = 0;
    data.allWpArtists.nodes.filter( i => i.artist?.artisttype === "artist" && i.artist?.festivaldate.length > 0).map((i) =>
      (stage === null || stage.includes(i.artist.scene[0].id)) &&
      (date === null || i.artist?.festivaldate[0]?.id === date)
        ? counter++
        : null
    );

    setVisibleArtists(counter);
  };

  const updatePlaces = (id) => {
    setPlace(id);
    const stages = [];
    data.allWpStages.nodes.map((i) =>
      i.stages.place[0].id === id ? stages.push(i.id) : null
    );
    setStage(stages);
  };

  const artists = [
    ...data.allWpArtists.nodes.filter(startsWithLetter),
    ...data.allWpArtists.nodes.filter(startsWithNumber),
  ].filter( i => i.artist?.artisttype === "artist" && i.artist?.festivaldate?.length > 0);

  const vjs = data.allWpArtists.nodes.filter((i) => i.artist.artisttype === "vj");




  return (
    <>
      {/* <div className="mb-4">
        <h2 className="mb-1  w-18 font-editorial ">
          {" "}
          {intl.formatMessage({ id: "Places" })}:{" "}
        </h2>
        <button
          className={`mr-2 mb-2 whitespace-nowrap border rounded-sm border-white px-2 py-0.5 text-sm ${
            place === null ? "text-utdf bg-white" : ""
          }`}
          onClick={() => {
            setStage(null);
            setPlace(null);
          }}
        >
          {intl.formatMessage({ id: "All" })}
        </button>
        {data.allWpPlaces.nodes.map((i, key) => (
          <button
            key={key}
            className={`mr-2 mb-2 whitespace-nowrap border rounded-sm border-white px-2 py-0.5 text-sm ${
              place === i.id ? "text-utdf bg-white" : ""
            }`}
            onClick={() => updatePlaces(i.id)}
          >
            {intl.locale === "pl" ? i.places.namePl : i.places.nameEn}
          </button>
        ))}
      </div>
      <div className="mb-4">
        <h2 className="mb-1  w-18 font-editorial ">
          {" "}
          {intl.formatMessage({ id: "Stages" })}:{" "}
        </h2>
        <button
          className={`mr-2 mb-2 whitespace-nowrap border rounded-sm border-white px-2 py-0.5 text-sm ${
            stage === null ? "text-utdf bg-white" : ""
          }`}
          onClick={() => {
            setStage(null);
            setPlace(null);
          }}
        >
          {intl.formatMessage({ id: "All" })}
        </button>
        {data.allWpStages.nodes.map((i, key) => (
          <button
            key={key}
            className={`mr-2 mb-2 whitespace-nowrap border rounded-sm border-white px-2 py-0.5 text-sm ${
              stage && stage.includes(i.id) ? "text-utdf bg-white" : ""
            }`}
            onClick={() => {
              setStage([i.id]);
              setPlace(null);
            }}
          >
            {intl.locale === "pl" ? i.title : i.stages.titleEn}
          </button>
        ))}
      </div>
      <div className="mb-8 ">
        <h2 className="mb-1  font-editorial ">
          {intl.formatMessage({ id: "Dates" })}:{" "}
        </h2>
        <button
          className={`mr-2  mb-2 whitespace-nowrap border rounded-sm border-white px-2 py-0.5 text-sm ${
            date === null ? "text-utdf bg-white" : ""
          }`}
          onClick={() => {
            setDate(null);
          }}
        >
          {intl.formatMessage({ id: "All" })}
        </button>
        {data.allWpDates.nodes.map((i, key) => (
          <button
            key={key}
            className={`mr-2 mb-2 whitespace-nowrap border rounded-sm border-white px-2 py-0.5 text-sm ${
              date === i.id ? "text-utdf bg-white" : ""
            }`}
            onClick={() => {
              setDate(i.id);
            }}
          >
            {i.title}
          </button>
        ))}
      </div> */}

      <div className="mb-8 ">
        <h2 className="mb-2 sm:mb-4 text-lg sm:text-2xl text-utdf-secondary  ">
          {intl.formatMessage({ id: "Dates" })}:{" "}
        </h2>
        <button
          className={` sm:text-xl font-bold mr-2  mb-2 whitespace-nowrap border rounded-sm border-utdf-secondary px-2 py-0.5 ${
            date === null
              ? "text-utdf bg-utdf-secondary"
              : "text-utdf-secondary"
          }`}
          onClick={() => {
            setDate(null);
          }}
        >
          {intl.formatMessage({ id: "All" })}
        </button>
        {data.allWpDates.nodes.map((i, key) => (
          <button
            key={key}
            className={`sm:text-xl font-bold  mr-2 mb-2 whitespace-nowrap border rounded-sm border-utdf-secondary px-2 py-0.5  ${
              date === i.id
                ? "text-utdf bg-utdf-secondary"
                : "text-utdf-secondary"
            }`}
            onClick={() => {
              setDate(i.id);
            }}
          >
            {i.title}
          </button>
        ))}
      </div>

      {visibleArtists === 0 ? <h2>:(</h2> : null}

      <ul
        className="grid gap-6 sm:gap-10"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
        }}
      >
        {artists.map(
          (i, key) =>
          (i.artist?.festivaldate && i.artist?.festivaldate[0])?.title.includes("2023") && (
              <li
                className={`basis-[270px] shrink-0 group ${
                  (stage === null || stage.includes(i.artist.scene[0].id)) &&
                  (date === null || i.artist?.festivaldate[0]?.id === date)
                    ? "block"
                    : "hidden"
                }`}
                key={key}
              >
                <Link
                  className="flex sm:flex-col sm:justify-between h-full gap-2"
                  to={`/artists/${i.slug}`}
                  title={i.title}
                >
                  <div className="basis-[7rem] shrink-0 w-full grow-0 overflow-clip aspect-1 sm:basis-auto ">
                    <GatsbyImage
                      image={
                        i.artist.photo
                          ? getImage(i.artist.photo.localFile)
                          : null
                      }
                      alt={i.artist.title}
                      className="w-full h-full  object-cover"
                    />
                  </div>
                  <div className="grow sm:flex sm:flex-col sm:justify-between">
                    <p className=" font-medium leading-none sm:mt-2 sm:text-2xl sm:leading-9 mb-auto group-hover:underline flex items-start gap-2">
                      {i.title.split(' live')[0]} {i.title.split(' live').length > 1 ? <span className="font-editorial italic text-base sm:text-xl"> live</span> : null } {i.artist.timetableOnly}
                    </p>
                    <div className="flex flex-col justify-between">
                      <div className="border-t mt-2 pt-2 border-utdf-border gap-4 flex justify-between">
                        <span>{i.artist?.festivaldate[0]?.title} </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            )
        )}
      </ul>
      <h2 className="max-w-7xl pt-10  mx-auto text-utdf-secondary font-bold text-4xl mb-8">
                  VJ
                </h2>

      <ul
        className="grid gap-6 sm:gap-10"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
        }}
      >
        {vjs.map(
          (i, key) =>
             (
              <li
                className={`basis-[270px] shrink-0 group`}
                key={key}
              >
                <Link
                  className="flex sm:flex-col sm:justify-between h-full gap-2"
                  to={`/artists/${i.slug}`}
                  title={i.title}
                >
                  <div className="basis-[7rem] shrink-0 w-full grow-0 overflow-clip aspect-1 sm:basis-auto ">
                    <GatsbyImage
                      image={
                        i.artist.photo
                          ? getImage(i.artist.photo.localFile)
                          : null
                      }
                      alt={i.artist.title}
                      className="w-full h-full  object-cover"
                    />
                  </div>
                  <div className="grow sm:flex sm:flex-col sm:justify-between">
                    <p className=" font-medium leading-none sm:mt-2 sm:text-2xl sm:leading-9 mb-auto group-hover:underline flex items-start gap-2">
                      {i.title.split(' live')[0]} {i.title.split(' live').length > 1 ? <span className="font-editorial italic text-base sm:text-xl"> live</span> : null } {i.artist.timetableOnly}
                    </p>

                  </div>
                </Link>
              </li>
            )
        )}
      </ul>
    </>
  );
};

const ArtistsSelectorWrapper = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query ShortArtistsList {
            allWpPlaces {
              nodes {
                id
                places {
                  nameEn
                  namePl
                }
              }
            }
            allWpStages(sort: { fields: slug }) {
              nodes {
                id
                slug
                stages {
                  place {
                    ... on WpPlaces {
                      id
                      places {
                        nameEn
                        namePl
                      }
                    }
                  }
                  titleEn
                }
                title
              }
            }
            allWpDates(sort: { fields: slug }) {
              nodes {
                title
                slug
                id
              }
            }
            allWpArtists(sort: { fields: slug }) {
              nodes {
                id
                title
                slug
                artist {
                  photo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  festivaldate {
                    ... on WpDates {
                      id
                      title
                    }
                  }
                  artisttype
                  hour
                  timetableOnly
                  scene {
                    ... on WpStages {
                      id
                      stages {
                        place {
                          ... on WpPlaces {
                            id
                            places {
                              nameEn
                              namePl
                            }
                          }
                        }
                        titleEn
                      }
                      title
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          return <ArtistsSelector data={data} />;
        }}
      />
    </>
  );
};

export default ArtistsSelectorWrapper;
