import * as React from "react";
import Layout from "../components/layout";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import { StaticQuery, graphql } from "gatsby";

import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

const ProjectsPage = () => {
  const intl = useIntl();
  return (
    <>
      <StaticQuery
        query={graphql`
          query Projects {
            allWpPage(sort: { fields: modified, order: DESC }) {
              edges {
                node {
                  modified
                  title
                  slug
                  content
                  page {
                    pageGroup
                    language
                    shortDescription
                  }
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const pages = data.allWpPage.edges;
          const projects = pages.filter(
            (i) =>
              i.node.page.pageGroup === "projects" &&
              i.node.page.language === intl.locale
          );

          return (
            <Layout>
              <main className="overflow-hidden">
                {/* <div className="absolute z-[-2]  h-[100vh] top-[-88px] left-[-20px]  w-[calc(100%+20px)]">
          <Image
            src="kapusta.png"
            className="h-full w-full"
            objectFit="cover"
            objectPosition="bottom center"
            alt=""
          />
        </div>
        <div className="absolute z-[-1] top-[calc(100vh-248px)] w-[calc(100%+20px)] h-40 from-transparent to-utdf bg-gradient-to-b left-[-20px] " /> */}

                <header className="px-4 py-10 sm:pt-[7.5rem]">
                  <h1 className="big-heading">
                    {intl.formatMessage({ id: "OurProjects" })}
                  </h1>
                </header>
                <div className="max-w-7xl mx-auto  space-y-16 lg:space-y-32 lg:py-8 px-8">
                  {projects.map((project, i) => (
                    <article className="group" key={i}>
                      <Link to={`/${project.node.slug}`}>
                        <div
                          className={`flex flex-wrap lg:flex-nowrap flex-col-reverse lg:flex-row gap-6 md:gap-11 items-center ${
                            i % 2 === 0 ? "lg:flex-row-reverse" : null
                          }`}
                        >
                          <div className="w-full lg:basis-[23.5rem] lg:shrink-0 space-y-4">
                            <h2 className="font-gambetta text-2xl lg:text-5xl">
                              {project.node.title}
                            </h2>
                            <p>{project.node.page.shortDescription}</p>
                            <span className="rounded-lg font-medium bg-utdf-secondary text-black  inline-block py-2 px-4">
                              {intl.formatMessage({ id: "More" })} &rarr;
                            </span>
                          </div>
                          <div className="aspect-[3/2] w-full overflow-hidden bg-white">
                            {project.node.featuredImage && (
                              <GatsbyImage
                                image={getImage(
                                  project.node?.featuredImage.node.localFile
                                )}
                                alt=""
                                className="w-full h-full"
                                objectFit="cover"
                              />
                            )}
                          </div>
                        </div>
                      </Link>
                    </article>
                  ))}
                  {(
                    <article className="group">
                      <Link to={`/ambient`}>
                        <div
                          className={`flex flex-wrap lg:flex-nowrap flex-col-reverse lg:flex-row gap-6 md:gap-11 items-center ${
                            projects.length % 2 === 0
                              ? "lg:flex-row-reverse"
                              : null
                          }`}
                        >
                          <div className="w-full lg:basis-[23.5rem] lg:shrink-0 space-y-4">
                            <h2 className="font-gambetta text-2xl lg:text-5xl">
                              Salony Ambientu
                            </h2>
                            <span className="rounded-lg font-medium bg-utdf-secondary text-black  inline-block py-2 px-4">
                              {intl.formatMessage({ id: "More" })} &rarr;
                            </span>
                          </div>
                          <div className="aspect-[3/2] w-full overflow-hidden bg-white">
                            <StaticImage
                              src="../images/bg-2022.jpg"
                              alt=""
                              className="w-full h-full"
                              objectFit="cover"
                            />
                          </div>
                        </div>
                      </Link>
                    </article>
                  )}
                </div>
              </main>
            </Layout>
          );
        }}
      ></StaticQuery>
    </>
  );
};

export default ProjectsPage;
