import * as React from "react";
import Partners from "../components/partners";
import Layout from "../components/layout";
import { useIntl } from "gatsby-plugin-react-intl";

const PartnersPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <main>
        <header className="px-4 py-10 sm:pt-[7.5rem]">
          <h1 className="big-heading">
            {intl.formatMessage({ id: "Partners" })}
          </h1>
        </header>
        <Partners />
      </main>
    </Layout>
  );
};

export default PartnersPage;
