import React, {useLayoutEffect} from "react";
import Layout from "../components/layout";
import Image from "../components/image";

const Page = ({ pageContext }) => {
  useLayoutEffect(() => {
    const images = document.querySelectorAll(".wp-block-image");
    images.forEach((image) => {
      image.classList.add("transform","transition","duration-500","ease-in-out","translate-y-6","opacity-40", "scale-[.9]", "grayscale");
      // add transform to image where it appears on screen
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.remove("translate-y-6", "opacity-40", "scale-[.9]", "grayscale");
          } else {
            entry.target.classList.add("translate-y-6", "opacity-40", "scale-[.9]", "grayscale");
          }
        });
      }, { threshold: 0.8 });
      observer.observe(image);
    });
  }, []);
  const { page } = pageContext;
  return (
    <Layout>
      <main className="pb-10 overflow-hidden">
        {/* <div className="absolute z-[-2]  h-[100vh] top-[-88px] left-[-20px]  w-[calc(100%+20px)]">
          <Image
            src="kapusta.png"
            className="h-full w-full"
            objectFit="cover"
            objectPosition="bottom center"
            alt=""
          />
        </div>
        <div className="absolute z-[-1] top-[calc(100vh-248px)] w-[calc(100%+20px)] h-40 from-transparent to-utdf bg-gradient-to-b left-[-20px] " /> */}

        <header className="px-4 py-10 sm:pt-[7.5rem]">
          <h1 className="big-heading max-w-3xl">{page.title}</h1>
        </header>
        <div
          className="prose-invert  prose-lg mx-auto max-w-2xl text-utdf-text px-4"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      </main>
    </Layout>
  );
};
export default Page;
