import * as React from "react";
import ArtistsSelector from "../components/artists-selector";
import Layout from "../components/layout";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Image from "../components/image";

const ArtistsPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      {/* <div className="absolute z-[-2]  h-[100vh] top-[-88px]  w-[calc(100% + 20px)] left-[-20px]">
        <Image
          src="kapusta.png"
          className="h-full w-full"
          objectFit="cover"
          objectPosition="bottom center"
          alt=""
        />
      </div>
      <div className="absolute z-[-1] top-[calc(100vh-248px)] h-[10rem] from-transparent to-utdf bg-gradient-to-b  w-[calc(100%+20px)] left-[-20px]" /> */}

      <main className="overflow-hidden pb-10 px-4 ">
        <header className="py-10 sm:pt-[7.5rem]">
          <h1 className="big-heading flex justify-between items-center gap-4 flex-wrap">
            {intl.formatMessage({ id: "Artists" })}
            <Link
                className="text-xl lg:text-2xl font-normal"
                to="/timetable"
              >
                TIMETABLE &rarr;
              </Link>
          </h1>
        </header>
        <div className="max-w-7xl mx-auto ">

        <ArtistsSelector />
        </div>
      </main>
    </Layout>
  );
};

export default ArtistsPage;
