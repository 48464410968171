import React, { useLayoutEffect, useState } from "react";
import ArtistsList from "../components/artists-list";
import ArtistsHome from "../components/artists-home";
import Layout from "../components/layout";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Partners from "../components/partners";
import NewsHome from "../components/news-home";
import Image from "../components/image";
import logo from "../images/logo.svg";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = () => {
  const intl = useIntl();

  let lastFlex = "justify-start items-start";

  const randomFlex = () => {
    const justify = ["justify-start", "justify-center", "justify-end"];
    const items = ["items-start", "items-center", "items-end"];
    let flex = `${justify[Math.floor(Math.random() * justify.length)]} ${
      items[Math.floor(Math.random() * items.length)]
    }`;
    if (flex === lastFlex || flex === "justify-center items-center") {
      flex = `${justify[Math.floor(Math.random() * justify.length)]} ${
        items[Math.floor(Math.random() * items.length)]
      }`;
    }
    lastFlex = flex;
    return `${flex}`;
  };

  useLayoutEffect(() => {
    const images = document.querySelectorAll(".rotate-gallery");
    const rotation = [
      "rotate-0",
      "rotate-0",
      "rotate-0",
    ];

    const flipImage = () => {
      images.forEach((image, index) => {
        image.classList.add("scale-125");
        setTimeout(() => {
          image.classList.add(
            rotation[Math.floor(Math.random() * rotation.length)]
          );
          image.classList.remove("scale-125");
          image.classList.remove("invisible");
        }, index * 700);
      });
      images.forEach((image, index) => {
        setTimeout(() => {
          image.classList.add("invisible");
          image.classList.remove([...rotation,]);
          if (index === (images.length - 1)) flipImage();
        }, index * 700 + 8000);
      });
    };
    flipImage();
  }, []);

  return (
    <>
      <Layout>
        <main>
          <header className=" -mt-[3.5rem] sm:-mt-[5.5rem] mb-40 ">
            <div className="relative min-h-[calc(100vh_-_2rem)] py-[4rem] sm:py-[6rem] flex items-center justify-center overflow-clip">
              <div
                className={`rotate-gallery mix-blend- dtransition dduration-1000   scale-125 transform3d absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/1.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/2.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/3.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/4.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/5.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/6.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/7.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/8.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/9.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/10.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/11.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/12.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>

              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/13.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/14.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/15.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/16.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/17.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/18.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/19.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/20.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/21.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/22.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/23.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/24.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>

              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/25.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/26.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/27.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/28.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/29.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/30.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/31.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/32.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/33.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/34.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>
              <div
                className={`rotate-gallery dtransition dduration-1000   scale-125 transform3d origin-center absolute inset-0 z-[-1] overflow-clip flex ${randomFlex()} invisible`}
              >
                <StaticImage
                  src={`../images/main/35.jpg`}
                  alt=""
                  className="object-contain max-w-[69vw] bg-utdf max-h-full"
                  imgClassName="opacity-50 grayscale"
                />
              </div>

              <div className="absolute inset-0 z-[-1] bg-gradient-radial from-black to-transparent opacity-80"></div>
              <div className="flex justify-center items-center space-y-10 md:space-y-20 flex-col ">
                <img
                  src={logo}
                  alt="Up To Date Festival Białystok"
                  className="max-w-[14rem] sm:max-w-[20rem] shrink-0 w-full mx-auto"
                />
                <h1 className="sr-only">Up To Date Festival Białystok</h1>
                <p className="text-3xl sm:text-[3rem] sm:leading-tight  text-left text-utdf-secondary">
                  <span className="font-gambetta italic font-medium uppercase">
                    {intl.formatMessage({ id: "FullDate" })}
                  </span>
                  <span className="font-gambetta  block">SECRET LOCATION</span>
                  <span className="font-gambetta  block">BIAŁYSTOK</span>
                </p>
                <a
                  className="bg-utdf-secondary border border-utdf-secondary rounded-md text-lg sm:text-2xl font-bold  text-utdf-dark px-3 py-2 sm:py-3 sm:px-4"
                  href="https://goingapp.pl/wydarzenie/up-to-date-festival-2023/czerwiec-2023"
                >
                  {intl.formatMessage({ id: "Get Tickets" })}
                </a>
              </div>
            </div>

            {/*     <div className="flex items-center justify-center mt-16 gap-8"> <Link
                className="border border-utdf-border  py-3 px-4"
                to="/timetable"
              >
                TIMETABLE
          </Link>  </div> */}
          </header>

          <ArtistsHome />

          <NewsHome />
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
