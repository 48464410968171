import React from "react";
import Layout from "../components/layout";
import Image from "../components/image";

const News = ({ pageContext }) => {
  const { news } = pageContext;
  return (
    <Layout>
       {/* <div className="absolute z-[-2]  h-[100vh] top-[-88px] left-[-20px]  w-[calc(100%+20px)]">
          <Image
            src="kapusta.png"
            className="h-full w-full"
            objectFit="cover"
            objectPosition="bottom center"
            alt=""
          />
        </div>
        <div className="absolute z-[-1] top-[calc(100vh-248px)] w-[calc(100%+20px)] h-40 from-transparent to-utdf bg-gradient-to-b left-[-20px] " /> */}
    <main className="overflow-hidden pb-10">
      <header className="px-4 py-10 sm:pt-[7.5rem]">
        <h1 className="big-heading max-w-2xl mx-auto" dangerouslySetInnerHTML={{__html: news.title}} />
      </header>
      <div
          className="prose-invert  prose-lg mx-auto max-w-2xl text-utdf-text px-4"
          dangerouslySetInnerHTML={{ __html: news.content }}
      />
      </main>
    </Layout>
  );
};
export default News;
