import React from "react";
import { useIntl } from "gatsby-plugin-react-intl";

const Partners = () => {
  const intl = useIntl();
  return (
    <div className="max-w-5xl mx-auto">
      <div className="flex flex-col gap-4 text-center mb-12 items-center">
        <h2 className="mb-2 font-medium leading-tight ">
          Partner Strategiczny
          <br /> Up To Date Festival 2023
        </h2>
        <a href="https://electrum.pl" target="_blank" rel="noreferrer nofollow">
          <img
            className="w-full max-w-[16rem] mx-auto"
            src="/partnerzy/electrum.svg"
            alt="Electrum"
          />
        </a>
      </div>

      <h3 className="text-center">{intl.formatMessage({ id: "Partners" })}</h3>
      <div className="grid grid-cols-2 sm:grid-cols-4 mb-8 items-center">
        <a
          href="https://www.bialystok.pl/"
          target="_blank"
          rel="noreferrer nofollow"
        >
          <img
            className="w-full max-w-[12rem] mx-auto"
            src="/partnerzy/bialystok.svg"
            alt="Białystok"
          />
        </a>

        <a
          href="https://podlaskie.eu/"
          target="_blank"
          rel="noreferrer nofollow"
        >
          <img
            className="w-full max-w-[12rem] mx-auto"
            src="/partnerzy/podlaskie.svg"
            alt="Podlaskie"
          />
        </a>

        <a
          href="https://www.bialystok.pl/"
          target="_blank"
          rel="noreferrer nofollow"
        >
          <img
            className="w-full max-w-[12rem] mx-auto"
            src="/partnerzy/honorowy-bialystok.svg"
            alt="Białystok"
          />
        </a>

        <a
          href="https://podlaskie.eu/"
          target="_blank"
          rel="noreferrer nofollow"
        >
          <img
            className="w-full max-w-[12rem] mx-auto"
            src="/partnerzy/podlaskie-patronat.svg"
            alt="Podlaskie"
          />
        </a>

        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/monument23.svg"
          alt="Monument"
        />

        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/eean.svg"
          alt="EEA Grants"
        />

        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/heritage.svg"
          alt="Club Heritage"
        />

        <a
          href="https://goingapp.pl/wydarzenie/up-to-date-festival-2023/czerwiec-2023"
          target="_blank"
          rel="noreferrer nofollow"
        >
          <img
            className="w-full max-w-[12rem] mx-auto"
            src="/partnerzy/going.svg"
            alt="going"
          />
        </a>

        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/ra.svg"
          alt="Resident Advisor"
        />

        <a
          href="https://www.eastrent.pl/pl/"
          target="_blank"
          rel="noreferrer nofollow"
        >
          <img
            className="w-full max-w-[12rem] mx-auto"
            src="/partnerzy/east-rent.svg"
            alt="East Rent"
          />
        </a>

        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/strefa-jelenia.svg"
          alt="Strefa Jelenia"
        />

        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/mixer-truck.svg"
          alt="Mixer Truck"
        />
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/wild-zone.svg"
          alt="Wild Zone"
        />
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/waszczukowe.svg"
          alt="Waszczukowe"
        />
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/granko.svg"
          alt="Granko Agency"
        />

        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/pnh.svg"
          alt="Projekt New Hope"
        />

        {/*
      <a
        href="https://www.polskieradio.pl/10,Czworka"
        target="_blank"
        rel="noreferrer nofollow"
      >
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/czworka.svg"
          alt="Czwórka Polskie Radio"
        />
      </a>

      <a
        href="https://pronarwheels.com"
        target="_blank"
        rel="noreferrer nofollow"
      >
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/pronar.svg"
          alt="Pronar / Pronar Wheels"
        />
      </a>

  */}

        {/*
      <a
        href="https://www.biletomat.pl/festiwale/up-to-date-festival-2021-bialystok-10065/"
        target="_blank"
        rel="noreferrer nofollow"
      >
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/biletomat.svg"
          alt="biletomat"
        />
      </a>

      <a
        href="https://akadera.bialystok.pl/"
        target="_blank"
        rel="noreferrer nofollow"
      >
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/akadera.svg"
          alt="Radio Akadera"
        />
      </a>

      <a href="https://muno.pl/" target="_blank" rel="noreferrer nofollow">
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/muno.svg"
          alt="Muno"
        />
      </a>

      <a href="https://rytmy.pl" target="_blank" rel="noreferrer nofollow">
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/rytmy.svg"
          alt="Rytmy.pl"
        />
      </a>

      <a href="https://nowamuzyka.pl" target="_blank" rel="noreferrer nofollow">
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/nowa-muzyka.svg"
          alt="NowaMuzyka.pl"
        />
      </a>
      <a href="https://newonce.net/" target="_blank" rel="noreferrer nofollow">
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/newonce.svg"
          alt="newonce"
        />
      </a>

      <a href="https://poptown.eu/" target="_blank" rel="noreferrer nofollow">
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/poptown.svg"
          alt="poptown"
        />
      </a>
      <a
        href="https://aszdziennik.pl/"
        target="_blank"
        rel="noreferrer nofollow"
      >
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/asz.svg"
          alt="ASZ:dziennik"
        />
      </a> */}

        {/* <a href="https://www.jagermeister.com/pl-PL/produkt" target="_blank" rel="noreferrer nofollow">
          <img className="w-full max-w-[12rem] mx-auto" src="/partnerzy/scena-jelenia.svg" alt="Scena Jelenia" />
        </a>
        <a href="https://www.clipperpolska.pl/" target="_blank" rel="noreferrer nofollow">
          <img className="w-full max-w-[12rem] mx-auto" src="/partnerzy/clipper.svg" alt="Clipper" />
        </a>
        <a href="https://gornapolka.com/" target="_blank" rel="noreferrer nofollow">
          <img className="w-full max-w-[12rem] mx-auto" src="/partnerzy/gorna-polka.svg" alt="Górna Półka" />
        </a>
        <a href="https://zielinskiinterior.com/" target="_blank" rel="noreferrer nofollow">
          <img className="w-full max-w-[12rem] mx-auto" src="/partnerzy/zielinski.svg" alt="Zielinski Interior" />
        </a>
        <a href="http://biebrzanskie.pl/" target="_blank" rel="noreferrer nofollow">
          <img className="w-full max-w-[12rem] mx-auto" src="/partnerzy/biebrzanskie.svg" alt="Biebrzańskie" />
        </a>
        <a href="https://lewiatan.pl/" target="_blank" rel="noreferrer nofollow">
          <img className="w-full max-w-[12rem] mx-auto" src="/partnerzy/lewiatan.svg" alt="Lewiatan" />
        </a>
        <a href="https://facebook.com/FOMOklub" target="_blank" rel="noreferrer nofollow">
          <img className="w-full max-w-[12rem] mx-auto" src="/partnerzy/fomo.svg" alt="FOMO" />
        </a>
        <a href="https://bialostockagastronomia.pl/" target="_blank" rel="noreferrer nofollow">
          <img className="w-full max-w-[12rem] mx-auto"
            src="/partnerzy/bialostocka-gastronomia.svg"
            alt="Białostocka Gastronomia"
          />
        </a>
        <a href="https://redkroft.com/" target="_blank" rel="noreferrer nofollow">
          <img className="w-full max-w-[12rem] mx-auto" src="/partnerzy/redkroft.svg" alt="Redkroft" />
        </a> */}
      </div>

      <h3 className="text-center">{intl.formatMessage({ id: "MediaPartners" })}</h3>

      <div className="grid grid-cols-2 sm:grid-cols-5 mb-8  items-center">

      <a href="https://muno.pl/" target="_blank" rel="noreferrer nofollow">
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/muno.svg"
          alt="Muno"
        />
      </a>
        <a
          href="https://nowamuzyka.pl"
          target="_blank"
          rel="noreferrer nofollow"
        >
          <img
            className="w-full max-w-[12rem] mx-auto"
            src="/partnerzy/nowa-muzyka.svg"
            alt="NowaMuzyka.pl"
          />
        </a>

        <a
        href="https://www.polskieradio.pl/10,Czworka"
        target="_blank"
        rel="noreferrer nofollow"
      >
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/czworka.svg"
          alt="Czwórka Polskie Radio"
        />
      </a>

      <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/kampus.svg"
          alt="Radio Kampus"
        />


      <a
        href="https://akadera.bialystok.pl/"
        target="_blank"
        rel="noreferrer nofollow"
      >
        <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/akadera.svg"
          alt="Radio Akadera"
        />
      </a>



      <img
          className="w-full max-w-[12rem] mx-auto"
          src="/partnerzy/clot.svg"
          alt="Clot Magazine"
        />
      </div>
    </div>
  );
};

export default Partners;
