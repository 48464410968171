import * as React from "react";
import ArtistsList from "../components/artists-list";
import ArtistsHome from "../components/artists-home";
import Layout from "../components/layout";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Partners from "../components/partners";
import NewsHome from "../components/news-home";
import Image from "../components/image";
import logo from "../images/logo.svg";

const IndexPage = () => {
  const intl = useIntl();
  return (
    <>
      <Layout>
        <main>
          <header className="relative pt-20 mb-40">

            <div className="flex h-full justify-center items-center space-y-10 md:space-y-20 flex-col">
            <img
                  src={logo}
                  alt="Up To Date Festival Białystok"
                  className="max-w-[14rem] sm:max-w-[20rem] shrink-0 w-full mx-auto"
                />
              <h1 className="sr-only">Up To Date Festival Białystok

              </h1>
              <p className="text-3xl sm:text-[3rem] sm:leading-tight  text-left text-utdf-secondary">
                <span className="font-gambetta italic font-medium uppercase">
                {intl.formatMessage({ id: "FullDate" })}
                </span>
                <span className="font-gambetta  block">
                  SECRET LOCATION
                </span>
                <span className="font-gambetta  block">
                  BIAŁYSTOK
                </span>
              </p>
              <a
                className="bg-utdf-secondary border border-utdf-secondary rounded-md text-lg sm:text-2xl font-bold  text-utdf-dark px-3 py-2 sm:py-3 sm:px-4"
                href="https://goingapp.pl/wydarzenie/up-to-date-festival-2023/czerwiec-2023"
              >
                {intl.formatMessage({ id: "Get Tickets" })}
              </a>
            </div>

            {/*     <div className="flex items-center justify-center mt-16 gap-8"> <Link
                className="border border-utdf-border  py-3 px-4"
                to="/timetable"
              >
                TIMETABLE
          </Link>  </div> */}
          </header>

          <ArtistsHome />

          <NewsHome />

        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
