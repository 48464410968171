import * as React from "react";
import Layout from "../components/layout";
import { Link, useIntl } from "gatsby-plugin-react-intl";
import { StaticQuery, graphql } from "gatsby";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProjectsPage = () => {
  const intl = useIntl();
  return (
    <>
      <StaticQuery
        query={graphql`
          query LongNewsList {
            allWpPost(sort: { fields: date, order: DESC }) {
              nodes {
                title
                slug
                news {
                  language
                  featuredimage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => {
          const nodes = data.allWpPost.nodes;

          const news = nodes.filter((i) => i.news.language === intl.locale);

          return (
            <Layout>
              <main className="overflow-hidden">
                {/* <div className="absolute z-[-2]  h-[100vh] top-[-88px] left-[-20px]  w-[calc(100%+20px)]">
                  <Image
                    src="kapusta.png"
                    className="h-full w-full"
                    objectFit="cover"
                    objectPosition="bottom center"
                    alt=""
                  />
                </div>
                <div className="absolute z-[-1] top-[calc(100vh-248px)] w-[calc(100%+20px)] h-40 from-transparent to-utdf bg-gradient-to-b left-[-20px] " /> */}

                <header className="px-4 py-10 sm:pt-[7.5rem]">
                  <h1 className="big-heading">
                    {intl.formatMessage({ id: "News" })}
                  </h1>
                </header>
                <div className="max-w-7xl mx-auto  space-y-32 py-8 px-8">
                  <div className="grid md:grid-cols-2  gap-8 mb-32 md:gap-24 justify-between">
                    {news.map((i, key) => (
                      <article
                        key={key}
                        className="sm:block shrink w-full basis-[500px] sm:mb-40 sm:even:translate-y-48"
                      >
                        <Link to={`/news/${i.slug}`} className="block">
                          <GatsbyImage
                            image={
                              i.news.featuredimage
                                ? getImage(i.news.featuredimage.localFile)
                                : null
                            }
                            alt={i.title}
                            className=""
                            style={{ flexBasis: "80px" }}
                          />
                          <h2
                            className="mt-4 text-2xl"
                            dangerouslySetInnerHTML={{ __html: i.title }}
                          />
                        </Link>
                      </article>
                    ))}
                  </div>
                </div>
              </main>
            </Layout>
          );
        }}
      ></StaticQuery>
    </>
  );
};

export default ProjectsPage;
